<template>
  <div class="page-4o4">
    <img src="../../assets/images/404.png" alt="Page Not Found" />
    <h2 class="sec-title">{{ $t('pageNotFound') }}</h2>
    <button class="btn-pri" @click="action">
      {{ $t('backToHome') }}
    </button>
  </div>
</template>

<script>
export default {
  methods:{
    action(){
      this.$router.push({name: 'Home'})
    }
  }
};
</script>

<style>
</style>